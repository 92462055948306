// =========================================================
// * Vuetify Material Dashboard PRO - v2.1.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vuetify-material-dashboard-pro
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import Vue from 'vue'
import App from './App.vue'
import router from './my/router'
import store from './my/store'
import './my/plugins/axios'
import './my/plugins/base'
import './plugins/chartist'
import './my/plugins/vee-validate'
import './plugins/vue-world-map'
import i18n from './my/i18n'
import vuetify from './my/plugins/vuetify'
import auth from '@websanova/vue-auth'
import authBearer from '@websanova/vue-auth/dist/drivers/auth/bearer.esm.js'
import httpAxios from '@websanova/vue-auth/dist/drivers/http/axios.1.x.esm.js'
import routerVueRouter from '@websanova/vue-auth/dist/drivers/router/vue-router.2.x.esm.js'
import VueMomentLib from 'vue-moment-lib'

if (process.env.NODE_ENV === 'development') Vue.config.devtools = true
Vue.config.productionTip = true

Vue.axios.defaults.baseURL = store.state.apiHostname
Vue.axios.defaults.headers.common = {
    'X-Requested-With': 'XMLHttpRequest',
    'X-CSRF-TOKEN': store.state.xsrf,
    Accept: 'application/json',
}

Vue.router = router

Vue.use(auth, {
  auth: authBearer,
  http: httpAxios,
  router: routerVueRouter,
  token: [{ request: 'Authorization', response: 'Authorization', authType: 'bearer', foundIn: 'header' }],
  loginData: { url: 'api/login', method: 'POST', redirect: '/portal/profile', fetchUser: true },
  fetchData: { url: 'api/me', method: 'POST', enabled: true },
  refreshData: { url: 'api/refresh', method: 'POST', enabled: true, interval: 5 },
  logoutData: { url: 'api/logout', method: 'POST', redirect: '/', makeRequest: true },
  authRedirect: { path: '/' },
  forbiddenRedirect: { path: '/403' },
  notFoundRedirect: { path: '/404' },
  rolesKey: 'permissions',
})

Vue.axios.interceptors.response.use(
    (response) => {
        if (response.data.access_token) store.commit('SET_TOKEN', response.data.access_token)
        store.commit('SET_RESULT', response)
        store.commit('SET_MESSAGE', response.data.message)
        return response
    },
    (error) => {
        if (error.response.status === 401 && ['Unauthorized', 'TokenInvalid', 'TokenExpired', 'TokenBlacklisted'].indexOf(error.response.data.message) > -1) {
            Vue.auth.logout({
                redirect: { name: 'Login' },
            })
        } else if (error.response.status === 401 && ['LoginFailed'].indexOf(error.response.data.message) > -1) {
            store.commit('SET_RESULT', error.response)
            store.commit('SET_MESSAGE', error.response.data.message)
        } else if (error.response.status === 422) {
            store.commit('SET_RESULT', error.response.status)
            store.commit('SET_MESSAGE', error.response.data.errors)
        } else if (error.response.status === 500) {
            Vue.router.push({ name: '500' })
        }
    },
)

router.beforeEach((to, from, next) => {
    if (store.state.xsrf === null) {
        Vue.axios.get('/xsrf').then(function (response) {
            store.commit('SET_XSRF', response.data.xsrf)
        }).catch(function (error) {
            store.commit('SET_RESULT', error)
        })
    }

    // reset result and message in store
    store.commit('SET_RESULT', null)
    store.commit('SET_MESSAGE', null)

    if ((to.name === 'Login' || to.path === 'portal') && Vue.auth.check()) next('/portal/profile')
    if (!Vue.auth.check() && store.state.token) store.commit('SET_TOKEN', null)

    next()
})

Vue.use(VueMomentLib)

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App),
}).$mount('#app')
