import Vue from 'vue'
import VueI18n from 'vue-i18n'

import store from '@/my/store'

import nl from 'vuetify/lib/locale/nl'
import en from 'vuetify/lib/locale/en'
import nlValidate from 'vee-validate/dist/locale/nl.json'
import enValidate from 'vee-validate/dist/locale/en.json'

Vue.use(VueI18n)

const messages = {
  en: {
    ...require('@/locales/en.json'),
    $vuetify: en,
    validation: enValidate.messages,
  },
  nl: {
    ...require('@/locales/nl.json'),
    $vuetify: nl,
    validation: nlValidate.messages,
  },
}

var l = 'nl'
if (store.state.locale !== null) {
    l = store.state.locale
} else {
    store.commit('SET_LOCALE', l)
}

export default new VueI18n({
  locale: l,
  fallbackLocale: 'en',
  messages,
})
