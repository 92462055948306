<template>
  <v-container
    id="register"
    class="fill-height"
    tag="section"
  >
    <v-row justify="center">
      <v-slide-y-transition appear>
        <base-material-card
          color="primary"
          light
          max-width="100%"
        >
          <template #heading>
            <div class="text-center">
              <h1 class="display-2 font-weight-bold">
                {{ $tc('signup', 1) }}
              </h1>
            </div>
          </template>

          <v-tabs
            ref="tabs"
            v-model="internalValue"
            show-arrows
          >
            <v-tab
              v-for="(item, i) in items"
              :key="i"
              :ripple="false"
              disabled
            >
              {{ $tc(item, 1) }}
            </v-tab>
          </v-tabs>

          <div class="my-6" />

          <v-card-text>
            <v-tabs-items
              v-model="internalValue"
              light
            >
              <slot />
            </v-tabs-items>
          </v-card-text>

          <v-card-actions class="pb-4 pa-4">
            <v-btn
              v-show="internalValue > 0"
              :disabled="internalValue === 0"
              class="white--text"
              color="grey darken-2"
              min-width="125"
              @click="$emit('previous')"
            >
              {{ $tc('previous', 1) }}
            </v-btn>

            <v-spacer />

            <v-btn
              :disabled="!availableSteps.includes(internalValue + 1)"
              :loading="loading"
              color="primary"
              min-width="125"
              @click="$emit('next')"
            >
              {{ stepTitle }}
            </v-btn>
          </v-card-actions>
        </base-material-card>
      </v-slide-y-transition>
    </v-row>
  </v-container>
</template>

<script>
  // Mixins
  import Proxyable from 'vuetify/lib/mixins/proxyable'

  export default {
    name: 'BaseMaterialWizard',

    mixins: [Proxyable],

    props: {
      availableSteps: {
        type: Array,
        default: () => ([]),
      },
      loading: {
        type: Boolean,
        default: () => (false),
      },
      items: {
        type: Array,
        default: () => ([]),
      },
    },

    data: () => ({}),

    computed: {
      stepTitle () {
        if (this.internalValue === 0) {
          return this.$tc('start', 1)
        } else if (this.internalValue === this.items.length - 1) {
          return this.$tc('finish', 1)
        } else {
          return this.$tc('next', 1)
        }
      },
    },
  }
</script>

<style lang="sass">
</style>
