import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    clientHostname: process.env.VUE_APP_HOSTNAME,
    apiHostname: process.env.VUE_APP_API,
    loading: false,
    xsrf: null,
    token: null,
    locales: [
        {
            text: 'Nederlands',
            value: 'nl',
        },
        {
            text: 'English',
            value: 'en',
        },
    ],
    locale: null,
    message: null,
    result: null,
    darkMode: false,
    mini: false,
    barImage: 'records-side.png',
    barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
    drawer: null,
  },
  mutations: {
    TOGGLE_LOADING (state) {
      state.loading = !state.loading
    },
    SET_XSRF (state, payload) {
      state.xsrf = payload
    },
    SET_TOKEN (state, payload) {
      state.token = payload
    },
    SET_LOCALE (state, payload) {
      state.locale = payload
    },
    SET_MESSAGE (state, message) {
        state.message = message
    },
    SET_RESULT (state, payload) {
      state.result = payload
    },
    SET_DARK_MODE (state, payload) {
      state.darkMode = payload
    },
    SET_BAR_IMAGE (state, payload) {
      state.barImage = payload
    },
    SET_DRAWER (state, payload) {
      state.drawer = payload
    },
    SET_MINI (state, payload) {
      state.mini = payload
    },
    SET_SCRIM (state, payload) {
      state.barColor = payload
    },
  },
  actions: {},
  plugins: [createPersistedState()],
})
