/* eslint camelcase: ["error", {ignoreImports: true}] */

import Vue from 'vue'
import {
  extend,
  configure,
  ValidationObserver,
  ValidationProvider,
} from 'vee-validate'
import {
  alpha_num,
  confirmed,
  digits,
  email,
  max,
  max_value,
  min,
  min_value,
  numeric,
  required,
} from 'vee-validate/dist/rules'
import i18n from '../i18n'

configure({
  defaultMessage: (field, values) => {
    // override the field name.
    values._field_ = i18n.t(`fields.${field}`)

    return i18n.t(`validation.${values._rule_}`, values)
    },
})

extend('alpha_num', alpha_num)
extend('confirmed', confirmed)
extend('digits', digits)
extend('email', email)
extend('max', max)
extend('max_value', max_value)
extend('min', min)
extend('min_value', min_value)
extend('numeric', numeric)
extend('required', required)

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
