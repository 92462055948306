import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: () => import('@/my/views/pages/Index'),
      children: [
        {
          name: 'Login',
          path: '',
          component: () => import('@/my/views/pages/Login'),
        },
        {
          name: 'signup',
          path: '/signup',
          component: () => import('@/my/views/pages/Signup'),
        },
        {
          name: 'payment',
          path: '/signup/payment/:ssid',
          component: () => import('@/my/views/pages/Payment'),
        },
      ],
    },
    {
      path: '/portal/dashboard',
      component: () => import('@/my/views/portal/Index'),
      meta: { auth: true },
      children: [
        {
          name: 'dashboard',
          path: '',
          component: () => import('@/my/views/portal/Dashboard'),
          meta: { icon: 'mdi-view-quilt' },
        },
        {
          name: 'profile',
          path: '/portal/profile',
          component: () => import('@/my/views/portal/Profile'),
          meta: { icon: 'mdi-playlist-music' },
        },
        {
          name: 'company',
          path: '/portal/company',
          component: () => import('@/my/views/portal/Company'),
          meta: { icon: 'mdi-domain' },
        },
        {
          name: 'user',
          path: '/portal/users',
          component: () => import('@/my/views/portal/Users'),
          meta: { auth: ['customer/user/index'], localeCount: 2, icon: 'mdi-account-multiple' },
        },
        {
          name: 'subscription',
          path: '/portal/subscriptions',
          component: () => import('@/my/views/portal/Subscriptions'),
          meta: { auth: ['customer/subscription/index'], localeCount: 2, icon: 'mdi-domain' },
        },
        {
          name: 'music-upload',
          path: '/portal/musicuploads',
          component: () => import('@/my/views/portal/MusicUploads'),
          meta: { auth: ['customer/cloud/index'], icon: 'mdi-cloud-upload' },
        },
        {
          name: 'logout',
          path: '/portal/logout',
          component: () => import('@/my/views/pages/Logout'),
        },
      ],
    },
    {
      path: '/theme/pages',
      component: () => import('@/views/pages/Index'),
      children: [
        {
          name: 'Theme Lock',
          path: 'lock',
          component: () => import('@/views/pages/Lock'),
        },
        {
          name: 'Theme Login',
          path: 'login',
          component: () => import('@/views/pages/Login'),
        },
        {
          name: 'Theme Pricing',
          path: 'pricing',
          component: () => import('@/views/pages/Pricing'),
        },
        {
          name: 'Theme Register',
          path: 'register',
          component: () => import('@/views/pages/Register'),
        },
      ],
    },
    {
      path: '/theme',
      component: () => import('@/views/dashboard/Index'),
      children: [
        // Dashboard
        {
          name: 'Theme Dashboard',
          path: 'dashboard',
          component: () => import('@/views/dashboard/Dashboard'),
        },
        // Pages
        {
          name: 'Theme RTL',
          path: 'pages/rtl',
          component: () => import('@/views/dashboard/pages/Rtl'),
        },
        {
          name: 'Theme User Profile',
          path: 'pages/user',
          component: () => import('@/views/dashboard/pages/UserProfile'),
        },
        {
          name: 'Theme Timeline',
          path: 'pages/timeline',
          component: () => import('@/views/dashboard/pages/Timeline'),
        },
        // Components
        {
          name: 'Theme Buttons',
          path: 'components/buttons',
          component: () => import('@/views/dashboard/component/Buttons'),
        },
        {
          name: 'Theme Grid System',
          path: 'components/grid-system',
          component: () => import('@/views/dashboard/component/Grid'),
        },
        {
          name: 'Theme Tabs',
          path: 'components/tabs',
          component: () => import('@/views/dashboard/component/Tabs'),
        },
        {
          name: 'Theme Notifications',
          path: 'components/notifications',
          component: () => import('@/views/dashboard/component/Notifications'),
        },
        {
          name: 'Theme Icons',
          path: 'components/icons',
          component: () => import('@/views/dashboard/component/Icons'),
        },
        {
          name: 'Theme Typography',
          path: 'components/typography',
          component: () => import('@/views/dashboard/component/Typography'),
        },
        // Forms
        {
          name: 'Theme Regular Forms',
          path: 'forms/regular',
          component: () => import('@/views/dashboard/forms/RegularForms'),
        },
        {
          name: 'Theme Extended Forms',
          path: 'forms/extended',
          component: () => import('@/views/dashboard/forms/ExtendedForms'),
        },
        {
          name: 'Theme Validation Forms',
          path: 'forms/validation',
          component: () => import('@/views/dashboard/forms/ValidationForms'),
        },
        {
          name: 'Theme Wizard',
          path: 'forms/wizard',
          component: () => import('@/views/dashboard/forms/Wizard'),
        },
        // Tables
        {
          name: 'Theme Regular Tables',
          path: 'tables/regular-tables',
          component: () => import('@/views/dashboard/tables/RegularTables'),
        },
        {
          name: 'Theme Extended Tables',
          path: 'tables/extended-tables',
          component: () => import('@/views/dashboard/tables/ExtendedTables'),
        },
        {
          name: 'Theme Data Tabels',
          path: 'tables/data-tables',
          component: () => import('@/views/dashboard/tables/DataTables'),
        },
        // Maps
        {
          name: 'Theme Google Maps',
          path: 'maps/google-maps',
          component: () => import('@/views/dashboard/maps/GoogleMaps'),
        },
        {
          name: 'Theme Full Screen Map',
          path: 'maps/full-screen-map',
          component: () => import('@/views/dashboard/maps/FullScreenMap'),
        },
        // Root level
        {
          name: 'Theme Widgets',
          path: 'widgets',
          component: () => import('@/views/dashboard/Widgets'),
        },
        {
          name: 'Theme Charts',
          path: 'charts',
          component: () => import('@/views/dashboard/Charts'),
        },
        {
          name: 'Theme Calendar',
          path: 'calendar',
          component: () => import('@/views/dashboard/Calendar'),
        },
      ],
    },
    {
      path: '*',
      component: () => import('@/my/views/pages/Index'),
      children: [
        {
          name: '404 Error',
          path: '',
          component: () => import('@/my/views/pages/Error'),
        },
      ],
    },
  ],
})
