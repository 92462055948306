import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import store from '@/my/store'
import i18n from '@/i18n'
import nl from 'vuetify/lib/locale/nl'
import en from 'vuetify/lib/locale/en'
import '@/my/sass/overrides.sass'

Vue.use(Vuetify)

var d = false
if (store.state.darkMode !== null) {
    d = store.state.darkMode
} else {
    store.commit('SET_DARK_MODE', d)
}

const theme = {
  primary: '#70B615',
  secondary: '#FDD835',
  accent: '#9C27B0',
  info: '#00CAE3',
}

export default new Vuetify({
  lang: {
    locales: { nl, en },
    current: i18n.locale,
    // t: (key, ...params) => i18n.t(key, params),
  },
  theme: {
    themes: {
      dark: theme,
      light: theme,
    },
    dark: d,
  },
})
